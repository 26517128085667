.skills{
    width: 100%;
    flex-direction: column;
}

.skills-container{
    margin-top: 3rem;
    width: 100%;
    display: flex;
}

.skills-list{
    flex:1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    @media screen and (max-width: 900) {
        margin-right: 0;
        justify-content: center;
        align-items: center;
    }

    @media screen and (max-width: 1200px){
        flex: 1;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}

.skills-item{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 1rem;
    transition: all 0.3s ease-in-out;


    div{
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background-color: #fff;

        img{
            width: 50%;
            height: 50%;
        }

        &:hover{
            box-shadow: 0 0 25px #fef4f5;
        }

        @media screen and (min-width: 2000px) {
            width: 150px;
            height: 150px;
        }
    }

    p{
        font-weight: 500;
        margin-top: 0.5rem;

        @media screen and (min-width:2000px) {
            margin: 1rem 0.2rem;

            p{
                margin-top: 1rem;
            }
        }
    }
}